import { Autocomplete, Chip, createFilterOptions, Stack } from "@mui/material";
import { ReactComponent as DropIcon } from "../../resources/images/icons-chevron-double.svg";
import { ReactComponent as RemoveIcon } from "../../resources/images/icons-remove.svg";
import { TextField } from "../TextField";
import { Component, createRef } from "react";
import { MultiSelectorContent } from "./MultiSelectorContent";

export class GroupByMultiSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      valid: null
    };

    this.selectorContainerRef = createRef();

    this.filterOptions = createFilterOptions({
      matchFrom: "any",
      stringify: (option) => this.props.getOptionLabel?.(option) ?? option?.label
    });
  }

  onGetSelectorContainerSize() {
    const selectorContainerSize = this.selectorContainerRef?.current.getBoundingClientRect();
    this.setState({ selectorContainerSize });
  }

  validate(e) {
    const { validationMethod } = this.props;
    const { valid } = this.state;

    if (validationMethod) {
      const newValid = validationMethod(e, e ? e.target.value : null);
      if (newValid !== valid) this.setState({ valid: newValid });
    }
  }

  onInputChange(e) {
    const { onSearch } = this.props;

    if (e && onSearch) onSearch(e.target.value);
  }

  onBlur(e) {
    const { onBlur } = this.props;

    this.validate(e);

    if (onBlur) onBlur(e);
  }

  onRemoveItem(e, item) {
    const { onChange, value, getOptionId } = this.props;

    const newValues = value.filter((i) => getOptionId(i) !== getOptionId(item));

    onChange?.(e, newValues);
  }

  renderOption(props, option, state) {
    const { selectorContainerSize } = this.state;

    return (
      <MultiSelectorContent
        option={option}
        inputValue={state.inputValue}
        props={props}
        selectorContainerSize={selectorContainerSize}
        getItemLabel={this.props.getOptionLabel}
        getItemId={this.props.getOptionId}
      />
    );
  }

  componentDidMount() {
    const { isValidateOnMount } = this.props;

    this.onGetSelectorContainerSize();

    if (isValidateOnMount) {
      this.validate(null);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.validate(null);
    }
  }

  render() {
    const { valid } = this.state;
    const { label, placeHolder, validationText, helperText, className, ...autocompleteProps } =
      this.props;
    const { value } = autocompleteProps;
    return (
      <Stack
        className={`klayo-multiselector ${className ?? ""}`}
        direction='column'
        spacing={2}
        ref={this.selectorContainerRef}
      >
        <Autocomplete
          className='klayo-selector'
          autoComplete
          includeInputInList
          filterSelectedOptions
          popupIcon={<DropIcon />}
          clearIcon={<RemoveIcon />}
          disableClearable={true}
          handleHomeEndKeys
          ListboxProps={{
            className: "klayo-multiselector_dropdownlist group-by"
          }}
          ChipProps={{
            deleteIcon: <RemoveIcon />
          }}
          multiple
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              placeholder={placeHolder ? placeHolder : "Search"}
              error={valid === false}
              helperText={valid === false ? validationText : helperText}
              endAdornment={params.InputProps.endAdornment}
            />
          )}
          filterOptions={this.filterOptions}
          onInputChange={this.onInputChange.bind(this)}
          renderOption={this.renderOption.bind(this)}
          {...autocompleteProps}
        />
        <Stack
          className={`klaro-chiparray ${value.length ? "has-selected" : ""}`}
          direction='row'
          spacing={1}
          sx={{ flexWrap: "wrap" }}
        >
          {value.map((option) => {
            return (
              <Chip
                key={this.props.getOptionId(option)}
                label={this.props.getOptionLabel(option)}
                onDelete={(e) => this.onRemoveItem(e, option)}
                deleteIcon={<RemoveIcon />}
              />
            );
          })}
        </Stack>
      </Stack>
    );
  }
}
