import { Data } from "../Data";

export class UserTrainings extends Data {
  constructor(options) {
    super({
      api: `/Profile/Trainings${options.employeeId ? "?employeeId=" + options.employeeId : ""}`,
      callback: (data) => {
        options.resolve(data);
      },
      ...options
    });
  }

  static get(context, employeeId) {
    return new Promise((resolve, reject) => {
      new UserTrainings({ employeeId: employeeId, resolve, reject, context });
    });
  }
}
