import { Data } from "./Data";

export class Organization extends Data {
  static instance = null;

  //OrganizationStatus { Trial, Active, Inactive, Archived }
  static status = {
    trial: {
      label: "Trial"
    },
    active: {
      label: "Active"
    },
    inactive: {
      label: "Inactive"
    },
    archived: {
      label: "Archived"
    },
    resetting: {
      label: "Resetting"
    }
  };

  constructor(options) {
    super({
      api: "/Organization",
      callback: (data) => {
        options.resolve(this, false);
      },
      ...options
    });
  }

  onDataReceived(data) {
    this.organizationId = data.organization.organizationId;
    this.name = data.organization.name;
    this.showProficiency = data.organization.showProficiency;
    this.trialEndDate = data.organization.trialEndDate
      ? new Date(data.organization.trialEndDate)
      : null;
    this.isDemo = data.organization.isDemo;
    this.status = Object.values(Organization.status)[data.organization.status];
    this.demoOrgStatus = Object.values(Organization.status)[data.organization.demoOrgStatus];
    this.isTrial = this.status === Organization.status.trial;
    this.hasIntegration = data.organization.hasIntegration;
    this.hasActiveIntegration = data.organization.hasActiveIntegration;
    this.hasMultipleIntegrations = data.organization.hasMultipleIntegrations;
    this.address = data.organization.address;
    this.hasRegulationTasks = data.organization.hasRegulationTasks;

    if (this.isTrial) {
      const today = new Date();
      this.trialMillisecondsRemaining = this.trialEndDate - today;
      this.trialDaysRemaining = Math.floor(this.trialMillisecondsRemaining / (1000 * 60 * 60 * 24));
      this.trialExpired = this.trialMillisecondsRemaining < 0;
    }
  }

  static get(context) {
    return new Promise((resolve, reject) => {
      if (Organization.instance) resolve(Organization.instance, true);
      else Organization.instance = new Organization({ resolve, reject, context });
    });
  }
}
