import React, { Component } from "react";
import { Badge, Grid } from "@mui/material";
import { ProfileJobSection } from "./ProfileJobSection";
import { ProfileAttributesSection } from "./ProfileAttributesSection";
import { ProfileGapsSection } from "./ProfileGapsSection";
import { AppContext } from "../../common/AppContext";
import { Switch, Route, Redirect } from "react-router-dom";
import { PillTabs } from "../../components/PillTabs";
import { UserProfileBox } from "../../components/UserProfileBox";
import { ProfileTrainingSection } from "./ProfileTrainingSection";

export class ProfileLayout extends Component {
  static contextType = AppContext;

  static defaultProps = {
    readOnly: true,
    myView: false,
    treeMode: false
  };

  constructor(props) {
    super(props);

    this.state = {
      subPages: {
        job: {
          name: props.myView ? "My job" : "Job",
          allowRoot: true,
          slug: "",
          paths: ["/"],
          component: ProfileJobSection,
          hasAccess: () => true,
          isDefault: () => true
        },
        competencies: {
          name: props.myView ? "My competencies" : "Competencies",
          slug: "competencies",
          paths: ["/competencies", "/competencies/:id"],
          component: ProfileAttributesSection,
          hasAccess: () => true
        },
        gaps: {
          name: props.myView ? "My gaps" : "Gaps",
          slug: "gaps",
          paths: ["/gaps", "/gaps/:id"],
          component: ProfileGapsSection,
          hasAccess: () => true
        },
        trainings: {
          name: props.myView ? "My training" : "Training",
          slug: "trainings",
          paths: ["/trainings", "/trainings/:id"],
          component: ProfileTrainingSection,
          hasAccess: () => true
        }
      }
    };
  }

  updateSubPages() {
    const props = this.props;
    this.setState({
      subPages: {
        ...this.state.subPages,
        gaps: {
          name: props.myView ? "My gaps" : "Gaps",
          slug: "gaps",
          paths: ["/gaps", "/gaps/:id"],
          component: ProfileGapsSection,
          hasAccess: () => true,
          render: ({ children, isActive }) => (
            <Badge
              invisible={isActive || !props.profile?.gapAttributes?.attributes?.length}
              color='error'
              variant='dot'
              slotProps={{
                badge: {
                  style: {
                    top: "3px",
                    right: "-7px"
                  }
                }
              }}
            >
              {children}
            </Badge>
          )
        },
        trainings: props.organization?.hasIntegration
          ? {
              name: props.myView ? "My training" : "Training",
              slug: "trainings",
              paths: ["/trainings", "/trainings/:id"],
              component: ProfileTrainingSection,
              hasAccess: () => true
            }
          : undefined
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.profile !== this.props.profile ||
      prevProps.organization !== this.props.organization
    ) {
      this.updateSubPages();
    }
  }

  render() {
    const {
      organization,
      user,
      employee,
      trainings,
      theme,
      profile,
      basePath,
      location,
      readOnly,
      onDeleteAttribute,
      onFeedbackAction,
      onProficiencyChangeAction,
      onAddChangeAction,
      onViewDetails,
      onShowAttributeDetails,
      treeMode,
      onChangeViewMode,
      isInstructorViewing,
      onEditAttribute,
      onAddAttributeClickCb,
    } = this.props;
    const { subPages } = this.state;
    let path = location.pathname;
    if (path === "/") path = basePath;

    const relativePath = path.replace(basePath, "");
    const relativePathSplit = relativePath.split("/");
    const activeTab = relativePathSplit[1] || "";

    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <UserProfileBox
              user={employee}
              profile={profile}
              showViewDetailsButton={true}
              onViewDetails={onViewDetails}
              readOnly={readOnly}
            />
          </Grid>
        </Grid>

        <PillTabs
          user={employee}
          organization={organization}
          theme={theme}
          path={basePath + (activeTab !== "" ? "/" + activeTab : "")}
          basePath={basePath || "/"}
          pages={subPages}
          sx={{ margin: "41px 0" }}
        />

        <Switch>
          {Object.entries(subPages)
            .filter(([_, subPage]) => !!subPage)
            .map(([key, subPage]) => {
              const paths = subPage.paths.map((p) => basePath + p);
              if (subPage.allowRoot === true)
                paths.push(subPage.slug === "" ? "" : "/" + subPage.slug);
              if (employee && subPage.isDefault && subPage.isDefault(employee))
                paths.push(basePath);

              return (
                <Route
                  key={key}
                  exact={true}
                  path={paths}
                  render={(props) => {
                    return (
                      <div>
                        {React.createElement(subPage.component, {
                          ...props,
                          organization,
                          user,
                          employee,
                          trainings,
                          theme,
                          profile,
                          readOnly: readOnly,
                          treeMode: treeMode,
                          onDeleteAttribute: onDeleteAttribute,
                          onFeedbackAction: onFeedbackAction,
                          isInstructorViewing: isInstructorViewing,
                          onProficiencyChangeAction: onProficiencyChangeAction,
                          onAddChangeAction: onAddChangeAction,
                          onShowAttributeDetails: onShowAttributeDetails,
                          onChangeViewMode,
                          onEditAttribute: onEditAttribute,
                          onAddAttributeClickCb: onAddAttributeClickCb
                        })}
                      </div>
                    );
                  }}
                />
              );
            })}
          <Redirect to='/' />
        </Switch>
      </div>
    );
  }
}
