import { List, ListItem, ListItemText, Skeleton } from "@mui/material";
import { Component, React } from "react";
import { Dialog } from "../../components/dialogs/Dialog";
import { UserProfileBox } from "../../components/UserProfileBox";
import { Utils } from "../../common/Utils";

export class UserProfileDialog extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      user,
      profile,
      theme,
      onClose,
      onProfilePictureChange,
      readOnly,
      profilePictureReset,
      profilePictureUpdating
    } = this.props;

    return (
      <Dialog
        className='klayo-userprofiledialog'
        open={true}
        theme={theme}
        onClose={onClose}
        fullWidth
        maxWidth='sm'
        actions={[
          {
            label: "Close",
            variant: "filled",
            onClick: onClose
          }
        ]}
      >
        <UserProfileBox
          user={user}
          profile={profile}
          readOnly={readOnly}
          onProfilePictureChange={onProfilePictureChange}
          profilePictureReset={profilePictureReset}
          profilePictureUpdating={profilePictureUpdating}
          allowEditProfilePicture={true}
          paperSx={{ padding: "0", border: "none!important" }}
          showViewDetailsButton={false}
        />
        {profile ? (
          <List>
            {profile.dateOfBirth ? (
              <ListItem sx={{ paddingLeft: "0!important" }}>
                <ListItemText
                  primary='Date of birth'
                  secondary={Utils.formatDate(profile.dateOfBirth)}
                />
              </ListItem>
            ) : null}
            {profile.gender ? (
              <ListItem sx={{ paddingLeft: "0!important" }}>
                <ListItemText primary='Gender' secondary={profile.gender} />
              </ListItem>
            ) : null}
            {profile.locationName ? (
              <ListItem sx={{ paddingLeft: "0!important" }}>
                <ListItemText primary='Location' secondary={profile.locationName} />
              </ListItem>
            ) : null}
            {profile.organizationName ? (
              <ListItem sx={{ paddingLeft: "0!important" }}>
                <ListItemText primary='Company' secondary={profile.organizationName} />
              </ListItem>
            ) : null}
            {profile.reportsTo ? (
              <ListItem sx={{ paddingLeft: "0!important" }}>
                <ListItemText primary='Reporting manager' secondary={profile.reportsTo} />
              </ListItem>
            ) : null}
            {profile.startDate ? (
              <ListItem sx={{ paddingLeft: "0!important" }}>
                <ListItemText
                  primary='Start date'
                  secondary={Utils.formatDate(profile.startDate)}
                />
              </ListItem>
            ) : null}
            {profile.email ? (
              <ListItem sx={{ paddingLeft: "0!important" }}>
                <ListItemText
                  primary='Email'
                  secondary={
                    <a className='klaro_profile-email' href={"mailto:" + profile.email}>
                      {profile.email}
                    </a>
                  }
                />
              </ListItem>
            ) : null}
          </List>
        ) : (
          <List sx={{ marginTop: "6px" }}>
            <Skeleton width={70} height={30} />
            <Skeleton width={300} height={30} />
          </List>
        )}
      </Dialog>
    );
  }
}
