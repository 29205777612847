import { Component } from "react";
import { Paper as MuiPaper, Stack } from "@mui/material";
import { Link as RouteLink } from "react-router-dom";
import { ReactComponent as BackIcon } from "../resources/images/icons-arrow-left.svg";
import { Heading } from "./Heading";
import { Button } from "./Button";
import styled from "@emotion/styled";

export class Paper extends Component {
  static defaultProps = {
    padding: { xs: "24px", md: "24px 31px 24px 31px" },
    title: null,
    titleFontWeight: 600,
    floatingTitle: false,
    overflow: "hidden",
    titleFontSize: {
      xs: "18px",
      sm: "18px",
      md: "20px"
    }
  };

  render() {
    const {
      sx,
      className,
      padding,
      theme,
      title,
      floatingTitle,
      titleSize,
      actionLink,
      actionLinkText,
      children,
      backButton,
      onBack,
      borderFromBreakpoint,
      overflow,
      titleFontSize,
      titleFontWeight,
      titleSx,
      chipText,
      titleRightSide
    } = this.props;

    const style = {
      overflow
    };

    if (theme && borderFromBreakpoint) {
      style[theme.breakpoints.down(borderFromBreakpoint)] = {
        borderWidth: "0px",
        borderRadius: "0"
      };
      style[theme.breakpoints.up(borderFromBreakpoint)] = {
        borderWidth: "1px",
        borderRadius: "16px!important"
      };
    } else {
      style.borderWidth = "1px";
      style.borderRadius = "16px";
    }

    return (
      <MuiPaper
        className={"klayo-paper " + className}
        sx={{
          ...style,
          padding: padding,
          ...sx
        }}
      >
        {title && (
          <Stack direction='row' justifyContent='space-between'>
            <div>
              {backButton && (
                <StyledBackButton
                  variant='simple'
                  size='sm'
                  onClick={(e) => onBack(e, "back")}
                  startIcon={<BackIcon />}
                  label='Back'
                />
              )}
              <Heading
                className='klayo-paper_title'
                size={titleSize}
                text={title}
                chipText={chipText}
                sx={{
                  fontSize: titleFontSize,
                  fontWeight: titleFontWeight,
                  margin: floatingTitle ? "10px" : "0 0 24px 0",
                  position: floatingTitle ? "absolute" : null,
                  borderRadius: floatingTitle ? "5px" : null,
                  background: floatingTitle ? "white" : null,
                  zIndex: floatingTitle ? "9" : null,
                  padding: floatingTitle ? "10px" : null,
                  ...titleSx
                }}
              />
            </div>
            <div>
              {titleRightSide}
              {actionLink && actionLinkText && (
                <RouteLink to={actionLink}>{actionLinkText}</RouteLink>
              )}
            </div>
          </Stack>
        )}
        {children}
      </MuiPaper>
    );
  }
}

const StyledBackButton = styled(Button)`
  margin-bottom: 20px;
`;
